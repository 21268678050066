import React from 'react'

function ResellerProgram() {
  return (
    <section id='programa-de-revenda' className='flex min-h-screen w-full items-center justify-center bg-[#FE8D56]'>
      <div className='m-4 flex w-11/12 flex-col gap-8 md:m-8 md:w-10/12'>
        <header className='text-center text-4xl md:text-justify md:text-5xl'>Programa de Revenda Fani Semijoias</header>

        <hr className='border-2 border-black' />

        <div>
          <ul className='grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-12'>
            <li className='flex flex-col gap-4'>
              <header className='text-2xl font-medium md:text-3xl'>Comissão de até 45%</header>
              <p className='text-justify text-lg md:text-xl'>
                Aproveite a oportunidade de ganhar até 45% de comissão sobre vendas sem nenhum custo inicial. Nós
                cuidamos dos produtos, você cuida das vendas!
              </p>
            </li>
            <li className='flex flex-col gap-4'>
              <header className='text-2xl font-medium md:text-3xl'>Flexibilidade e Liberdade Financeira</header>
              <p className='text-justify text-lg md:text-xl'>
                Inicie seu negócio de revenda sem preocupações financeiras. Nosso programa oferece flexibilidade para
                você vender conforme sua disponibilidade e conquistar sua independência financeira.
              </p>
            </li>
            <li className='flex flex-col gap-4'>
              <header className='text-2xl font-medium md:text-3xl'>Reposição de Mercadorias</header>
              <p className='text-justify text-lg md:text-xl'>
                Garantimos a reposição rápida e eficiente das mercadorias vendidas, mantendo sempre seu estoque
                abastecido para não perder oportunidades de venda.
              </p>
            </li>
            <li className='flex flex-col gap-4'>
              <header className='text-2xl font-medium md:text-3xl'>Facilidade de Pagamento</header>
              <p className='text-justify text-lg md:text-xl'>
                Oferecemos múltiplas formas de pagamento aos seus clientes, como boletos bancários, cartão de crédito ou
                pix, para maximizar suas vendas.
              </p>
            </li>
            <li className='flex flex-col gap-4'>
              <header className='text-2xl font-medium md:text-3xl'>Suporte Contínuo</header>
              <p className='text-justify text-lg md:text-xl'>
                Conte com nosso suporte dedicado para auxiliar nas estratégias de venda e garantir seu sucesso como
                revendedor.
              </p>
            </li>
            <li className='flex flex-col gap-4'>
              <header className='text-2xl font-medium md:text-3xl'>Rescisão Flexível</header>
              <p className='text-justify text-lg md:text-xl'>
                Você pode rescindir o contrato a qualquer momento, com aviso prévio de 15 dias, oferecendo flexibilidade
                e segurança nas suas operações.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default ResellerProgram
