import React from 'react'
import collectionImage1 from 'assets/images/collection1.jpg'
import collectionImage2 from 'assets/images/collection2.jpg'
import collectionImage3 from 'assets/images/collection3.jpg'
import collectionImage4 from 'assets/images/collection4.jpg'
import collectionImage5 from 'assets/images/collection5.jpg'

function OurCollection() {
  return (
    <section id='nossa-colecao' className='flex w-full items-center justify-center'>
      <div className='grid grid-cols-2 gap-1 md:grid-cols-5 w-full'>
        <img
          src={collectionImage1}
          alt='collection'
          loading='lazy'
          className='w-full h-full'
        />

        <img
          src={collectionImage2}
          alt='collection'
          loading='lazy'
          className='w-full h-full'
        />

        <img
          src={collectionImage3}
          alt='collection'
          loading='lazy'
          className='w-full h-full'
        />

        <img
          src={collectionImage4}
          alt='collection'
          loading='lazy'
          className='w-full h-full'
        />

        <img
          src={collectionImage5}
          alt='collection'
          loading='lazy'
          className='w-full h-full'
        />
      </div>
    </section>
  )
}

export default OurCollection
